import React from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import striptags from 'striptags';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import ContactButton from '../components/ContactButton';
import useI18n from '../utils/useI18n';

import '../styles/components/_components.faqs.scss';

const Faqs = ({ pageContext: { records, switchLang }, location }) => {
  const { t } = useI18n();
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('faqs.meta.title')}
        description={t('faqs.meta.description')}
        keywords={t('faqs.meta.keywords')}
        pathname={location.pathname}
        jsonLD={[
          {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: records.map((record) => ({
              '@type': 'Question',
              name: record.Faq.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: striptags(record.Faq.answer),
              },
            })),
          },
        ]}
      />
      <div className="o-wrapper c-section c-section--small">
        <h1>{t('faqs.title')}</h1>
        <h2 className="o-callout">{t('faqs.text')}</h2>
        <Accordion className="c-faqs u-10/12@desktop u-push-1/12@desktop">
          {records.map((record) => (
            <AccordionItem key={record.Faq.id} className="c-faqs__item">
              <AccordionItemHeading>
                <AccordionItemButton className="c-faqs__title">
                  <RightCircleOutlined />
                  <h3>{record.Faq.question}</h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel
                dangerouslySetInnerHTML={{ __html: record.Faq.answer }}
                className="c-faqs__content animated fadeIn"
              />
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <ContactButton section />
    </Layout>
  );
};

export default Faqs;
